import LogoutIcon from "@mui/icons-material/Logout";
import { IconButton, SvgIcon } from "@mui/material";
import useLogout from "src/hooks/use-logout";

const LogoutButton = () => {
  const { performLogout  } = useLogout();

  return (
    <IconButton onClick={performLogout }>
      <SvgIcon>
        <LogoutIcon />
      </SvgIcon>
    </IconButton>
  );
};

export default LogoutButton;
