import { createSlice } from "@reduxjs/toolkit";
import { objFromArray, objFromArrayGuid } from "src/utils/obj-from-array";

const initialState = {
  contacts: {
    byId: {},
    allIds: [],
  },
  currentThreadId: undefined,
  threads: {
    byId: {},
    allIds: [],
  },
  currentAssistantId: undefined,
  assistants: {
    byId: {},
    allIds: [],
  },
  session: {},
};

const reducers = {
  // Chat
  getAssistants(state, action) {
    const assistants = action.payload;

    state.assistants.byId = [];
    state.assistants.allIds = [];

    state.assistants.byId = objFromArrayGuid(assistants);
    state.assistants.allIds = Object.keys(state.assistants.byId);
  },
  setCurrentAssistant(state, action) {
    state.currentAssistantId = action.payload;
  },
  createSessionWithMessage(state, action) {
    const { assistantGuid, message, sessionId } = action.payload;
    const assistant = state.assistants.byId[assistantGuid];

    if (assistant) {
      assistant.session = sessionId;
      assistant.messages = [];
      assistant.messages.push(message);
    }
  },
  addMessage(state, action) {
    const { assistantGuid, message } = action.payload;
    const assistant = state.assistants.byId[assistantGuid];

    if (assistant) {
      assistant.messages.push(message);
    }
  },
  updateMessage(state, action) {
    const { assistantGuid, message } = action.payload;
    const assistant = state.assistants.byId[assistantGuid];
    if (assistant) {
      const assistantMessage = assistant.messages.find((x) => x.id === message.id);
      if (assistantMessage) {
        assistantMessage.contentType = "text";
        assistantMessage.body = message.body;
      }
    }
  },
  updateMessageContentType(state, action) {
    const { assistantGuid, messageId, type } = action.payload;
    const assistant = state.assistants.byId[assistantGuid];
    if (assistant) {
      const assistantMessage = assistant.messages.find((x) => x.id === messageId);
      if (assistantMessage) {
        assistantMessage.contentType = type;
      }
    }
  },
  updateLastMessage(state, action) {
    const { assistantGuid, message } = action.payload;
    const assistant = state.assistants.byId[assistantGuid];
    if (assistant) {
      const assistantMessage = assistant.messages[assistant.messages.length - 1];
      if (assistantMessage) {
        assistantMessage.contentType = "text";
        assistantMessage.body = message.body;
      }
    }
  },
  updateMessageError(state, action) {
    const { assistantGuid, message } = action.payload;
    const assistant = state.assistants.byId[assistantGuid];
    if (assistant) {
      const assistantMessage = assistant.messages.find((x) => x.id === message.id);
      if (assistantMessage) {
        assistantMessage.contentType = message.contentType;
        assistantMessage.body = message.body;
      }
    }
  },
  //
  getContacts(state, action) {
    const contacts = action.payload;

    state.contacts.byId = objFromArray(contacts);
    state.contacts.allIds = Object.keys(state.contacts.byId);
  },
  getThreads(state, action) {
    const threads = action.payload;

    state.threads.byId = objFromArray(threads);
    state.threads.allIds = Object.keys(state.threads.byId);
  },
  getThread(state, action) {
    const thread = action.payload;

    if (thread) {
      state.threads.byId[thread.id] = thread;

      if (!state.threads.allIds.includes(thread.id)) {
        state.threads.allIds.unshift(thread.id);
      }
    }
  },
  markThreadAsSeen(state, action) {
    const threadId = action.payload;
    const thread = state.threads.byId[threadId];

    if (thread) {
      thread.unreadCount = 0;
    }
  },
  setCurrentThread(state, action) {
    state.currentThreadId = action.payload;
  },
};

export const slice = createSlice({
  name: "chat",
  initialState,
  reducers,
});

export const { reducer } = slice;
