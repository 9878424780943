import { globalUrlsConfig } from "src/config";
import { useAuth } from "./use-auth";

const useLogout = () => {
  const { logout } = useAuth();

  const performLogout = () => {
    logout({ returnTo: globalUrlsConfig.logout_return_to });
  };

  return { performLogout };
};

export default useLogout;
