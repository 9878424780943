import React from "react";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { auth0Config } from "src/config";
import { useRouter } from "src/hooks/use-router";

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = auth0Config.issuer_base_url;
  const clientId = auth0Config.client_id;

  const history = useRouter();

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: auth0Config.base_url,
        audience: auth0Config.audience,
        scope: auth0Config.scope
      }}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export const AuthConsumer = ({ children }) => {
  const auth = useAuth0();

  return children(auth);
};

export default Auth0ProviderWithHistory;