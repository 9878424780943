import React, { createContext, useReducer } from "react";
import { useContext } from "react";
import CustomDialog from "src/components/notifications/Dialog";
import Loader from "src/components/notifications/Loader";
import SubBar from "src/components/notifications/SubBar";
import Toast from "src/components/notifications/Toast";
import { generateUniqueId } from "src/utils/notifications";

const NotificationsContext = createContext();

const initialState = {
  toast: null,
  subBar: null,
  dialog: null,
  loading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_TOAST":
      return { ...state, toast: action.payload };
    case "REMOVE_TOAST":
      return {
        ...state,
        toast: null,
      };
    case "SHOW_DIALOG":
      return { ...state, dialog: action.payload };
    case "CLOSE_DIALOG":
      return { ...state, dialog: null };
    case "SHOW_SUBBAR":
      return { ...state, subBar: { content: action.payload } };
    case "HIDE_SUBBAR":
      return { ...state, subBar: null };
    case "CLOSE_SUBBAR":
      return { ...state, subBar: action.payload };
    case "SHOW_LOADER":
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export const NotificationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const addToast = (message, type) => {
    const id = generateUniqueId();
    dispatch({ type: "ADD_TOAST", payload: { id, message, type } });
  };

  const addDefaultErrorToast = () => {
    const id = generateUniqueId();
    let message =
      "An unexpected error has occurred. Please try again or contact technical support.";
    let type = "error";
    dispatch({ type: "ADD_TOAST", payload: { id, message, type } });
  };

  const removeToast = (id) => {
    dispatch({ type: "REMOVE_TOAST", payload: id });
  };

  const showDialog = (content, code) => {
    const payload = { content, code }
    dispatch({ type: "SHOW_DIALOG", payload: payload });
  };

  const closeDialog = () => {
    dispatch({ type: "CLOSE_DIALOG" });
  };

  const showSubBar = (content) => {
    if (state.subBar?.status != "closed")
      dispatch({ type: "SHOW_SUBBAR", payload: content });
  };

  const closeSubBar = () => {
    dispatch({ type: "CLOSE_SUBBAR", payload: { status: "closed" } });
  };

  const hideSubBar = () => {
    dispatch({ type: "HIDE_SUBBAR" });
  };

  const showLoader = (content) => {
    dispatch({ type: "SHOW_LOADER", payload: content });
  };

  return (
    <NotificationsContext.Provider
      value={{
        state,
        addToast,
        addDefaultErrorToast,
        removeToast,
        showDialog,
        closeDialog,
        showSubBar,
        closeSubBar,
        showLoader,
        hideSubBar,
      }}
    >
      {children}
      <Loader state={state}></Loader>
      <Toast state={state} removeToast={removeToast} />
      <CustomDialog
        state={state}
        closeDialog={closeDialog}
        showLoader={showLoader}
        addDefaultErrorToast={addDefaultErrorToast}
      ></CustomDialog>
      <SubBar
        state={state}
        closeSubBar={closeSubBar}
        addDefaultErrorToast={addDefaultErrorToast}
        showLoader={showLoader}
      ></SubBar>
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error("You can use this hook only within NotificationProvider");
  }
  return context;
};
