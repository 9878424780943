export const tokens = {
  common: {
    languageChanged: 'common.languageChanged'
  },
  nav: {
    //dashboard: 'nav.dashboard',
    chatAssistants: 'nav.chatAssistants',
    settings: 'nav.settings',
    account: 'nav.account',
    users: 'nav.users',
    billing: 'nav.billing',
    apiAccess: 'nav.apiAccess',
    helpCenter: 'nav.helpCenter',
    quickStartGuide: 'nav.quickStartGuide',
    faq: 'nav.faq',
    apiDocumentation: 'nav.apiDocumentation',
  },
  dashboard: {
    title: 'dashboard.title',
    last24h: 'dashboard.last24h',
    messages: 'dashboard.messages',
    chatSessions: 'dashboard.messages'
  }
};
