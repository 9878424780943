import { useCallback, useEffect } from "react";
import { useAuth } from "src/hooks/use-auth";
import { paths } from "src/paths";

const Page = () => {
  const { loginWithRedirect } = useAuth();

  const handle = useCallback(async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const returnTo = searchParams.get("returnTo");
    await loginWithRedirect({
      returnTo: returnTo || paths.assistants.list,
    });
  }, [loginWithRedirect]);

  useEffect(() => {
    handle();
  }, []);

  return null;
};

export default Page;
