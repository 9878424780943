import { authRoutes } from "./auth";
import { commonRoutes } from "./common";

import { Outlet } from "react-router-dom";
import { lazy } from "react";

import { Layout as PortalLayout } from "src/layouts/dashboard";
import { WelcomeGuard } from "src/guards/welcome-guard";

const ChatAssistants = lazy(() =>
  import("src/pages/dashboard/assistants/list")
);
const WelcomeWizard = lazy(() => import("src/pages/welcome-wizard/index"));

const Chat = lazy(() => import("src/pages/dashboard/assistants/chat"));
const EditAssistant = lazy(() => import("src/pages/dashboard/assistants/edit"));
const CreateAssistant = lazy(() =>
  import("src/pages/dashboard/assistants/create")
);
const EditAccount = lazy(() => import("src/pages/dashboard/settings/account"));
const Users = lazy(() => import("src/pages/dashboard/settings/users"));
const Billing = lazy(() => import("src/pages/dashboard/settings/billing"));
const Pricing = lazy(() => import("src/pages/pricing"));
const QuickStartGuide = lazy(() => import("src/pages/quick-start-guide"));

export const adminRoutes = [
  {
    element: (
      <PortalLayout>
        <WelcomeGuard>
          <Outlet />
        </WelcomeGuard>
      </PortalLayout>
    ),
    children: [
      {
        index: true,
        element: <ChatAssistants />,
      },
      {
        path: "assistants/chat/:id/:firstSession?",
        element: <Chat />,
      },
      {
        path: "assistants/edit/:id",
        element: <EditAssistant />,
      },
      {
        path: "create-assistant",
        element: <CreateAssistant />,
      },
      {
        path: "edit-account",
        element: <EditAccount />,
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "billing",
        element: <Billing />,
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
      {
        path: "quick-start-guide",
        element: <QuickStartGuide />,
      },
    ],
  },
  {
    path: "welcome-wizard",
    element: (
      <WelcomeGuard>
        <WelcomeWizard />
      </WelcomeGuard>
    ),
  },
  ...authRoutes,
  ...commonRoutes,
];
