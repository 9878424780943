import { Box, Stack, Typography } from "@mui/material";
import { globalNamesConfig } from "src/config";

export const TenantSwitch = (props) => {
  return (
    <>
      <Stack alignItems="center" direction="row" spacing={2} {...props}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography color="inherit" variant="h5">
            {globalNamesConfig.app_name}
          </Typography>
        </Box>
      </Stack>
    </>
  );
};
