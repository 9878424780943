import { lazy } from "react";
import { Outlet } from "react-router-dom";
import { GuestGuard } from "src/guards/guest-guard";

// Auth0
const Auth0CallbackPage = lazy(() => import("src/pages/auth/auth0/callback"));
const Auth0LoginPage = lazy(() => import("src/pages/auth/auth0/login"));

export const authRoutes = [
  {
    path: "auth",
    children: [
      {
        path: "auth0",
        element: (
          <GuestGuard>
            <Outlet />
          </GuestGuard>
        ),
        children: [
          {
            path: "callback",
            element: <Auth0CallbackPage />,
          },
          {
            path: "login",
            element: <Auth0LoginPage />,
          },
        ],
      },
    ],
  },
];
