import axiosInstance from ".";
import { apiConfig } from "src/config";
import { useAuth0 } from "@auth0/auth0-react";
import useLogout from "src/hooks/use-logout";

const useApi = () => {
  const { getAccessTokenSilently, getIdTokenClaims, logout } = useAuth0();

  const { performLogout } = useLogout();

  const baseUrl = apiConfig.api_base_url;

  const checkTokenExpired = async () => {
    getIdTokenClaims().then((res) => {
      if (res) {
        const actualEpoch = Math.floor(Date.now() / 1000);
        if (res.exp < actualEpoch) {
          performLogout();
        }
      }
    });
  };

  const getToken = async () => {
    await checkTokenExpired();
    return await getAccessTokenSilently();
  };

  const get = async (url) => {
    try {
      const token = await getToken();

      const response = await axiosInstance.get(baseUrl + url, {
        headers: { Authorization: "Bearer " + token },
      });
      if (response.status !== 200) {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getWithParams = async (url, params) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axiosInstance.get(baseUrl + url, {
        params: params,
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      if (response.status !== 200) {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const post = async (url, data, widgetRequest) => {
    let headers;

    if (widgetRequest) {
      const widgetKey = localStorage.getItem("widgetKey");
      const clientId = localStorage.getItem("clientId");
      headers = {
        "x-widget-key": widgetKey,
        "x-client-id": clientId,
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    } else {
      const token = await getAccessTokenSilently();
      headers = {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    }

    try {
      const response = await axiosInstance.post(baseUrl + url, data, {
        headers: headers,
      });

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const postFormData = async (url, data) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axiosInstance.post(baseUrl + url, data, {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
        },
      });

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getWithCustomAuth = async (url) => {
    try {
      const widgetKey = localStorage.getItem("widgetKey");
      const clientId = localStorage.getItem("clientId");

      const response = await axiosInstance.get(baseUrl + url, {
        headers: { "x-widget-key": widgetKey, "x-client-id": clientId },
      });
      if (response.status !== 200) {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return { get, post, postFormData, getWithParams, getWithCustomAuth };
};

export default useApi;
