import { authRoutes } from "./auth";
import { commonRoutes } from "./common";

import { lazy } from "react";
import { Outlet } from "react-router-dom";

import { Layout as PortalLayout } from "src/layouts/dashboard";
import { WelcomeGuard } from "src/guards/welcome-guard";

const Chat = lazy(() => import("src/pages/dashboard/assistants/chat"));

export const userRoutes = [
  {
    element: (
      <PortalLayout>
        <WelcomeGuard>
          <Outlet />
        </WelcomeGuard>
      </PortalLayout>
    ),
    children: [
      {
        index: true,
        element: <Chat />,
      },
      {
        path: "assistants/chat/:id",
        element: <Chat />,
      },
    ],
  },
  ...authRoutes,
  ...commonRoutes,
];
