import React from "react";
import { AppBar, Box, Typography, Icon, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useRouter } from "src/hooks/use-router";
import useService from "src/services/subscription/useService";
import { paths } from "src/paths";
import { subBarHeigth } from "src/theme/utils";

function SubBar(props) {
  const { state, closeSubBar, addDefaultErrorToast, showLoader } = props;

  const router = useRouter();
  const { getCustomerPortalUrl, getSubscriptionStatus } = useService();

  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const handleClickHere = () => {
    showLoader(true);
    getSubscriptionStatus()
      .then((res) => {
        if (res.status == "incomplete") {
          handleGetCustomerPortalUrlAndRedirect();
        } else {
          router.push(paths.pricing);
        }
      })
      .catch((err) => {
        addDefaultErrorToast();
      })
      .finally(() => showLoader(false));
  };

  const handleGetCustomerPortalUrlAndRedirect = () => {
    getCustomerPortalUrl()
      .then((res) => {
        window.location.href = res.customerPortalUrl;
      })
      .catch((err) => {
        addDefaultErrorToast();
      });
  };

  const handleClose = () => {
    closeSubBar();
  };

  return state.subBar &&
    state.subBar.status !== "closed" &&
    window.location.pathname != paths.welcomeWizard &&
    !window.location.pathname.includes(paths.widgetChat) &&
    !window.location.pathname.includes(paths.errorWidgetChat) ? (
    <AppBar
      position="fixed"
      style={{
        backgroundColor: "rgb(247 126 51)",
        height: subBarHeigth,
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        boxShadow: "none",
        top: 0,
        zIndex: 9999,
      }}
    >
      <Box sx={{ alignItems: "center", display: "flex" }}>
        <Typography
          variant="body1"
          style={{
            flexGrow: 1,
            color: "#fff",
            fontSize: mdUp ? "15px" : "10px",
          }}
        >
          {state.subBar.content.alertMessage}{" "}
          <strong onClick={handleClickHere} style={{ cursor: "pointer" }}>
            {state.subBar.content.alertClickMessage}
          </strong>
        </Typography>
        <Icon
          color="inherit"
          sx={{
            cursor: "pointer",
            marginRight: "20px",
            display: "inline-flex",
          }}
          aria-label="close"
        >
          <CloseIcon onClick={handleClose} />
        </Icon>
      </Box>
    </AppBar>
  ) : null;
}

export default SubBar;
