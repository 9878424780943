import PropTypes from "prop-types";
import Settings03Icon from "@untitled-ui/icons-react/build/esm/Settings03";
import { Box, ButtonBase, SvgIcon, Tooltip } from "@mui/material";

export const SettingsButton = (props) => (
  <Tooltip title="Settings">
    <Box
      sx={{
        display: "none",
        backgroundColor: "background.paper",
        borderRadius: "50%",
        top: 0,
        boxShadow: 16,
        margin: (theme) => theme.spacing(1.5),
        position: "fixed",
        right: 0,
        zIndex: (theme) => theme.zIndex.appBar,
        marginRight: "60px",
      }}
      {...props}
    >
      <ButtonBase
        sx={{
          backgroundColor: "primary.main",
          borderRadius: "50%",
          color: "primary.contrastText",
          p: "10px",
        }}
      >
        <SvgIcon style={{ width: "20px", height: "20px" }}>
          <Settings03Icon />
        </SvgIcon>
      </ButtonBase>
    </Box>
  </Tooltip>
);

SettingsButton.propTypes = {
  onClick: PropTypes.func,
};
