import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import useService from "src/services/subscription/useService";

const customPortalCodes = [461];
const pricingCodes = [460, 462, 463, 464];

function CustomDialog(props) {
  const { state, closeDialog, showLoader, addDefaultErrorToast } = props;

  const router = useRouter();

  const { getCustomerPortalUrl } = useService();

  const handleButtonClick = () => {
    let code = state?.dialog?.code;
    if (pricingCodes.includes(code)) {
      router.push(paths.pricing);
      closeDialog();
    } else if (customPortalCodes.includes(code)) {
      handleGetCustomerPortalUrlAndRedirect();
      closeDialog();
    }
  };

  const handleGetCustomerPortalUrlAndRedirect = () => {
    showLoader(true);
    getCustomerPortalUrl()
      .then((res) => {
        window.location.href = res.customerPortalUrl;
      })
      .catch(() => {
        addDefaultErrorToast();
      })
      .finally(() => {
        showLoader(false);
      });
  };

  return (
    <Dialog maxWidth={"lg"} open={state.dialog !== null} onClose={closeDialog}>
      <IconButton
        aria-label="close"
        onClick={closeDialog}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText mr={3} id="alert-dialog-description">
          {state.dialog?.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleButtonClick} variant="contained">
          Manage subscription
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomDialog;
