export const objFromArray = (arr, key = 'id') => arr.reduce((accumulator, current) => {
  accumulator[current[key]] = current;
  return accumulator;
}, {});


export const objFromArrayGuid = (arr, key = 'assistantGuid') => arr.reduce((accumulator, current) => {
  accumulator[current[key]] = current;
  return accumulator;
}, {});
