import { useNotifications } from "src/contexts/notification-context";
import { useUser } from "./use-user";

const allCustomCodes = [422, 429, 460, 461, 462, 463, 464];

export const useResponse = () => {
  const { user } = useUser();
  const { addToast, showLoader, showDialog, state, closeSubBar, hideSubBar } =
    useNotifications();

  const handleChatErrorMessage = (response) => {
    let message =
      "An unexpected error occurred, refresh page and try again. If this does not help, please contact technical support.";

    if (response && response.code === 429) message = "Too many requests.";
    handleToast(message, "error");
  };

  const handleErrorResponse = (error, callbackFunction = () => {}) => {
    if (!error) {
      handleDefaultErrorToast();
    } else {
      let errorResponse = error.response;
      if (user?.isAdmin) {
        if (
          errorResponse?.status &&
          allCustomCodes.includes(errorResponse.status)
        ) {
          handleCustomErrorStatus(errorResponse);
        } else {
          handleDefaultErrorToast();
        }
      }
    }

    callbackFunction();
  };

  const handleCustomErrorStatus = (response) => {
    let status = response.status;
    switch (status) {
      case 422:
        response.data
          ? addToast(response.data, "error")
          : handleDefaultErrorToast();
        break;
      case 429:
        addToast("Too many requests.", "error");
        break;
      case 460:
        showDialog(
          "You can’t perfrom this action because you don’t have an active subscription. Click here to pick your plan.", status
        );
        break;
      case 461:
        showDialog(
          "You can’t perfrom this action because there is an issue with your subscription. Click here to fix it.", status
        );
        break;
      case 462:
        showDialog(
          "You can’t perfrom this action because your trial has ended. You can upgrade by clicking here.", status
        );
        break;
      case 463:
        showDialog(
          "You can’t perfrom this action because your subscription has reached daily message limit. You can upgrade by clicking here.", status
        );
        break;
      case 464:
        showDialog(
          "You can’t perfrom this action because your subscription has reached monthly character limit. You can upgrade by clicking here.", status
        );
        break;
      default:
        break;
    }
  };

  const handleToast = (message, type) => {
    addToast(message, type);
  };

  const handleDefaultErrorToast = () => {
    addToast(
      "An unexpected error occurred, refresh page and try again. If this does not help, please contact technical support.",
      "error"
    );
  };

  const handleSetLoading = (value) => {
    showLoader(value);
  };

  return {
    handleErrorResponse,
    handleSetLoading,
    handleToast,
    isLoading: state.loading,
    handleChatErrorMessage,
    closeSubBar,
    hideSubBar,
  };
};
