import { useEffect } from "react";
import PropTypes from "prop-types";
import { useAuth } from "src/hooks/use-auth";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { Issuer } from "src/utils/auth";
import { SplashScreen } from "src/components/splash-screen";

const loginPaths = {
  [Issuer.Amplify]: paths.auth.amplify.login,
  [Issuer.Auth0]: paths.auth.auth0.login,
  [Issuer.Firebase]: paths.auth.firebase.login,
  [Issuer.JWT]: paths.auth.jwt.login,
};

export const AuthGuard = ({ children }) => {
  const router = useRouter();
  const issuer = "Auth0";
  const { isAuthenticated, isLoading } = useAuth();

  useEffect(() => {
    const checkAuth = () => {
      if (!isAuthenticated) {
        const searchParams = new URLSearchParams({
          returnTo: window.location.href,
        }).toString();
        const href = loginPaths[issuer] + `?${searchParams}`;
        router.replace(href);
      }
    };

    if (!isLoading) {
      checkAuth();
    }
  }, [isAuthenticated, issuer, router, isLoading]);

  return isAuthenticated && !isLoading ? (
    <>{children} </>
  ) : (
    <SplashScreen></SplashScreen>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};
