import React from "react";
import { Alert, Snackbar } from "@mui/material";

function Toast({ state, removeToast }) {
  const handleClose = (id) => {
    removeToast(id);
  };

  return (
    <>
      <Snackbar
        key={state.toast?.id}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={state.toast !== null}
        autoHideDuration={5000}
        onClose={() => handleClose(state.toast?.id)}
      >
        <Alert
          onClose={() => handleClose(state.toast?.id)}
          severity={state.toast?.type}
        >
          {state.toast?.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Toast;
