import { Helmet } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { RTL } from "./components/rtl";
import { SplashScreen } from "./components/splash-screen";
import { SettingsButton } from "./components/settings/settings-button";
import { SettingsDrawer } from "./components/settings/settings-drawer";
import { Toaster } from "./components/toaster";
import {
  SettingsConsumer,
  SettingsProvider,
} from "./contexts/settings-context";
import Auth0ProviderWithHistory, {
  AuthConsumer,
} from "./contexts/auth/auth0-context";
import { gtmConfig } from "./config";
import { useNprogress } from "./hooks/use-nprogress";
import { useAnalytics } from "./hooks/use-analytics";
import { AppRouter } from "./routes";
import { store } from "./store";
import { createTheme } from "./theme";
// Remove if react-quill is not used
import "react-quill/dist/quill.snow.css";
// Remove if react-draft-wysiwyg is not used
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// Remove if simplebar is not used
import "simplebar-react/dist/simplebar.min.css";
// Remove if mapbox is not used
import "mapbox-gl/dist/mapbox-gl.css";
// Remove if locales are not used
import "./locales/i18n";
import { SubscriptionProvider } from "./contexts/subscription-context";
import { NotificationsProvider } from "./contexts/notification-context";
import { UserConsumer, UserProvider } from "./contexts/user-context";
import { NoSsr } from "@mui/material";

export const App = () => {
  useAnalytics(gtmConfig);
  useNprogress();

  return (
    <ReduxProvider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Auth0ProviderWithHistory>
          <AuthConsumer>
            {(auth) => (
              <SettingsProvider>
                <SettingsConsumer>
                  {(settings) => {
                    if (!settings.isInitialized) {
                    }

                    const theme = createTheme({
                      colorPreset: settings.colorPreset,
                      contrast: settings.contrast,
                      direction: settings.direction,
                      paletteMode: settings.paletteMode,
                      responsiveFontSizes: settings.responsiveFontSizes,
                    });

                    const showSlashScreen = auth.isLoading;

                    return (
                      <ThemeProvider theme={theme}>
                        <Helmet>
                          <meta
                            name="color-scheme"
                            content={settings.paletteMode}
                          />
                          <meta
                            name="theme-color"
                            content={theme.palette.neutral[900]}
                          />
                        </Helmet>
                        <RTL direction={settings.direction}>
                          <CssBaseline />
                          {showSlashScreen ? (
                            <SplashScreen />
                          ) : (
                            <NotificationsProvider>
                              <UserProvider>
                                <UserConsumer>
                                  {(userData) => {
                                    return (
                                      <>
                                        <SubscriptionProvider>
                                          <AppRouter
                                            isAdmin={userData?.user?.isAdmin}
                                          ></AppRouter>
                                        </SubscriptionProvider>

                                        <SettingsButton
                                          onClick={settings.handleDrawerOpen}
                                        />
                                        <SettingsDrawer
                                          canReset={settings.isCustom}
                                          onClose={settings.handleDrawerClose}
                                          onReset={settings.handleReset}
                                          onUpdate={settings.handleUpdate}
                                          open={settings.openDrawer}
                                          values={{
                                            colorPreset: settings.colorPreset,
                                            contrast: settings.contrast,
                                            direction: settings.direction,
                                            paletteMode: settings.paletteMode,
                                            responsiveFontSizes:
                                              settings.responsiveFontSizes,
                                            stretch: settings.stretch,
                                            layout: settings.layout,
                                            navColor: settings.navColor,
                                          }}
                                        />
                                      </>
                                    );
                                  }}
                                </UserConsumer>
                              </UserProvider>
                            </NotificationsProvider>
                          )}
                          <Toaster />
                        </RTL>
                      </ThemeProvider>
                    );
                  }}
                </SettingsConsumer>
              </SettingsProvider>
            )}
          </AuthConsumer>
        </Auth0ProviderWithHistory>
      </LocalizationProvider>
    </ReduxProvider>
  );
};
