import { auth0Config } from "src/config";

export const signUpUrl =
  "https://" +
  auth0Config.issuer_base_url +
  "/authorize?response_type=code&client_id=" +
  auth0Config.client_id +
  "&redirect_uri=" +
  auth0Config.base_url +
  "&scope=SCOPE&state=STATE&screen_hint=signup";
