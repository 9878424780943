import useApi from "src/api/useApi";

const useService = () => {
  const api = useApi();

  const showWelcomeWizard = async () => {
    return api.get("api/User/showwelcomewizard");
  };

  const changePassword = async (newPassword, repeatNewPassword) => {
    return api.post("api/User/ChangePassword", {
      newPassword,
      repeatNewPassword,
    });
  };

  const getUserSettings = async () => {
    return api.get("api/User/GetUserSettings");
  };

  const setUserLanguage = async (Language) => {
    return api.post("api/User/setuserlanguage", { Language });
  };

  const sendVerificationMail = async () => {
    return api.post("api/User/verifyemail", {});
  };

  const isEmailVerified = async () => {
    return api.get("api/User/isemailverified ");
  };

  return {
    changePassword,
    getUserSettings,
    setUserLanguage,
    sendVerificationMail,
    isEmailVerified,
    showWelcomeWizard,
  };
};

export default useService;
