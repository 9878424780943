export const auth0Config = {
  base_url: process.env.REACT_APP_AUTH0_BASE_URL,
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  issuer_base_url: process.env.REACT_APP_AUTH0_ISSUER_BASE_URL,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  scope: process.env.REACT_APP_AUTH0_SCOPE,
};

export const apiConfig = {
  api_base_url: process.env.REACT_APP_API_BASE_URL,
};

export const gtmConfig = {
  container_id: process.env.REACT_APP_GTM_CONTAINER_ID,
};

export const permissionConfig = {
  admin_permission_name: process.env.REACT_APP_PERMISSION_ADMIN_NAME,
};

export const globalNamesConfig = {
  app_name: process.env.REACT_APP_GLOBAL_APP_NAME,
};

export const globalUrlsConfig = {
  app_base_url: process.env.REACT_APP_GLOBAL_APP_BASE_URL,
  logo_url: process.env.REACT_APP_GLOBAL_APP_LOGO_URL,
  logo_url_with_text: process.env.REACT_APP_GLOBAL_APP_LOGO_URL_WITH_TEXT,
  landing_page_url: process.env.REACT_APP_GLOBAL_LANDING_PAGE_URL,
  widget_scr: process.env.REACT_APP_GLOBAL_WIDGET_SCR,
  logout_return_to: process.env.REACT_APP_GLOBAL_LOGOUT_RETURN_TO,
  quick_start_guide: process.env.REACT_APP_GLOBAL_QUICK_START_GUIDE
};

export const globalDataValues = {
  max_file_size_in_megabytes: process.env.REACT_APP_GLOBAL_DATA_MAX_FILE_SIZE,
  max_add_message_characters: process.env.REACT_APP_CHAT_MAX_ADD_MESSAGE_CHARACTERS,
};
