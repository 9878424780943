import { useRoutes } from "react-router-dom";
import { adminRoutes } from "./admin";
import { userRoutes } from "./user";

export const AppRouter = (props) => {
  const { isAdmin } = props;
  const routes = isAdmin ? adminRoutes : userRoutes;
  const pages = useRoutes(routes);

  return pages;
};
