import useApi from "src/api/useApi";

const useService = () => {
  const api = useApi();

  const getSubscriptionStatus = () => {
    return api.get("api/subscription/GetSubscriptionStatus");
  };

  const getSubscriptionPlans = () => {
    return api.get("api/subscription/GetSubscriptionPlans");
  };

  const getCustomerPortalUrl = () => {
    return api.get("api/subscription/GetCustomerPortalUrl");
  };

  const getSubscriptionOptions = () => {
    return api.get("api/subscription/GetSubscriptionOptions");
  };

  const getCheckoutSessionUrl = async (SubscriptionPlanGuid, BillingPeriod = "M") => {
    return api.post("api/subscription/GetCheckoutSessionUrl", {
      SubscriptionPlanGuid,
      BillingPeriod,
    });
  };

  const createcheckoutsession = async () => {
    return api.post("api/subscription/createcheckoutsession", {});
  };

  const createcustomerportal = async () => {
    return api.post("api/subscription/createcustomerportal", {});
  };
  
  return {
    getSubscriptionStatus,
    getSubscriptionPlans,
    getCustomerPortalUrl,
    getSubscriptionOptions,
    getCheckoutSessionUrl,
    createcheckoutsession,
    createcustomerportal
  };
};

export default useService;
