import React, { createContext, useEffect, useState } from "react";
import useService from "src/services/subscription/useService";
import { useUser } from "src/hooks/use-user";
import { useNotifications } from "./notification-context";
import { useResponse } from "src/hooks/use-response";

export const SubscriptionContext = createContext();

export const SubscriptionProvider = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [subscription, setSubscription] = useState({});

  const subscriptionService = useService();

  const { user } = useUser();
  const { showSubBar } = useNotifications();
  const { handleErrorResponse } = useResponse();

  useEffect(() => {
    getSubscriptionData();
  }, [user?.isAdmin, user?.showWelcomeWizard]);

  const getSubscriptionData = () => {
    if (user?.isAdmin && !user.showWelcomeWizard) {
      setIsLoaded(false);
      subscriptionService
        .getSubscriptionStatus()
        .then((res) => {
          setIsLoaded(true);
          setSubscription(res);
          handleSubcriptionStatus(res.status);
        })
        .catch((err) => {
          handleErrorResponse(err);
          setIsLoaded(true);
        });
    }
  };

  const handleSubcriptionStatus = (status) => {
    if (user?.isAdmin) {
      switch (status) {
        case "canceled":
          showSubBar({
            alertMessage: "You don’t have an active subscription.",
            alertClickMessage: "Click here to pick your plan.",
          });
          break;
        case "incomplete":
          showSubBar({
            alertMessage: "There is an issue with your subscription.",
            alertClickMessage: "Click here to fix it.",
          });
          break;
        case "trial":
          showSubBar({
            alertMessage: "You are running on a free trial.",
            alertClickMessage: "You can upgrade by clicking here.",
          });
          break;
        case "trial_ended":
          showSubBar({
            alertMessage: "Your trial has ended.",
            alertClickMessage: "You can upgrade by clicking here.",
          });
          break;
        default:
          break;
      }
    }
  };

  const subscriptionContextValue = {
    dailyMessageLimit: subscription.dailyMessageLimit,
    monthlyCharacterLimit: subscription.monthlyCharacterLimit,
    status: subscription.status,
    usedCharacters: subscription.usedCharacters,
    usedMessages: subscription.usedMessages,
    planName: subscription.planName,
    getSubscriptionData,
    isLoaded,
  };

  return (
    <SubscriptionContext.Provider value={subscriptionContextValue}>
      {children}
    </SubscriptionContext.Provider>
  );
};
