import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SvgIcon } from "@mui/material";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { tokens } from "src/locales/tokens";
import { paths } from "src/paths";

export const useSections = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return [
      {
        items: [
          {
            title: t(tokens.nav.chatAssistants),
            path: paths.assistants.list,
            icon: (
              <SvgIcon fontSize="small">
                <QuestionAnswerIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.settings),
            icon: (
              <SvgIcon fontSize="small">
                <SettingsApplicationsIcon />
              </SvgIcon>
            ),
            items: [
              {
                title: t(tokens.nav.account),
                path: paths.settings.account,
              },
              {
                title: t(tokens.nav.users),
                path: paths.settings.users,
              },
              {
                title: t(tokens.nav.billing),
                path: paths.billing,
              },
            ],
          },
          {
            title: t(tokens.nav.helpCenter),
            icon: (
              <SvgIcon fontSize="small">
                <HelpOutlineIcon />
              </SvgIcon>
            ),
            items: [
              {
                title: t(tokens.nav.quickStartGuide),
                path: paths.helpCenter.quickStartGuide,
              },
            ],
          },
        ],
      },
    ];
  }, [t]);
};
