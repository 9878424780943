import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { globalNamesConfig } from 'src/config';

export const Seo = (props) => {
  const { title } = props;

  const fullTitle =  globalNamesConfig.app_name + ' | ' + title;

  return (
    <Helmet>
      <title>
        {fullTitle}
      </title>
    </Helmet>
  );
};

Seo.propTypes = {
  title: PropTypes.string
};
