import { lazy } from "react";
import SignUpPage from "src/pages/auth/auth0/signup";
import SignInPage from "src/pages/auth/auth0/signin";
import Error401Page from "src/pages/401";
import Error404Page from "src/pages/404";
import Error500Page from "src/pages/500";
import NoAssistantsPage from "src/pages/user-no-assistans";
import WidgetErrorPage from "src/pages/widget-chat/error-page";

const WidgetChat = lazy(() => import("src/pages/widget-chat/index"));

export const commonRoutes = [
  {
    path: "widget-chat/:guid",
    element: <WidgetChat />,
  },
  {
    path: "no-assistants",
    element: <NoAssistantsPage />,
  },
  {
    path: "widget-error",
    element: <WidgetErrorPage />,
  },
  {
    path: "401",
    element: <Error401Page />,
  },
  {
    path: "404",
    element: <Error404Page />,
  },
  {
    path: "500",
    element: <Error500Page />,
  },
  {
    path: "signin",
    element: <SignInPage />,
  },
  {
    path: "signup",
    element: <SignUpPage />,
  },
  {
    path: "*",
    element: <Error404Page />,
  },
];
