import { useCallback, useEffect } from "react";
import { signUpUrl } from "src/utils/auth-utils";

const Page = () => {
  const handle = useCallback(async () => {
    window.location.href = signUpUrl;
  }, []);

  useEffect(() => {
    handle();
  }, []);

  return null;
};

export default Page;
