export const paths = {
  welcomeWizard: "/welcome-wizard",
  userNoAssistatns: "/no-assistants",
  pricing: "/pricing",
  billing: "/billing",
  widgetChat: "/widget-chat",
  errorWidgetChat: "/widget-error",
  assistants: {
    chat: "/assistants/chat/",
    list: "/",
    createAssistant: "/create-assistant",
    editAssistant: "/assistants/edit/"
  },
  settings: {
    account: "edit-account",
    users: "users"
  },
  helpCenter: {
    quickStartGuide: "quick-start-guide"
  },
  auth: {
    auth0: {
      callback: "/auth/auth0/callback",
      login: "/auth/auth0/login",
    },
    jwt: {
      login: "/auth/jwt/login",
      register: "/auth/jwt/register",
    },
    firebase: {
      login: "/auth/firebase/login",
      register: "/auth/firebase/register",
    },
    amplify: {
      confirmRegister: "/auth/amplify/confirm-register",
      forgotPassword: "/auth/amplify/forgot-password",
      login: "/auth/amplify/login",
      register: "/auth/amplify/register",
      resetPassword: "/auth/amplify/reset-password",
    },
  },
  401: "/401",
  404: "/404",
  500: "/500",
};
