import { tokens } from '../tokens';

export const pl = {
  [tokens.nav.dashboard]: 'Pulpit',
  [tokens.nav.chatAssistants]: 'Asystenci czatu',
  [tokens.nav.settings]: 'Ustawienia',
  [tokens.nav.account]: 'Konto',
  [tokens.nav.users]: 'Użytkownicy',
  [tokens.nav.billing]: 'Rozliczenia',
  [tokens.nav.apiAccess]: 'Dostęp API',
  [tokens.nav.helpCenter]: 'Centrum pomocy',
  [tokens.nav.quickStartGuide]: 'Podręcznik szybkiej obsługi',
  [tokens.nav.faq]: 'FAQ',
  [tokens.nav.apiDocumentation]: 'Dokumentacja API',
};
