import { Box, Stack, Typography } from "@mui/material";
import { Logo } from "./logo";
import { useTheme } from "@emotion/react";

export const SplashScreen = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "background.paper",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
        left: 0,
        p: 3,
        position: "fixed",
        top: 0,
        width: "100vw",
        zIndex: 1400,
      }}
    >
      <Stack width={80} height={80}>
        <Logo />
      </Stack>
      <Typography variant="subtitle2" color={theme.palette.primary.dark}>
        Loading... Please wait.
      </Typography>
    </Box>
  );
};
