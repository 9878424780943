import { Box, IconButton, Stack, SvgIcon } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuth } from "src/hooks/use-auth";

const LogoutHeader = () => {
  const { logout } = useAuth();
  return (
    <Box justifyContent={"right"} width={"100%"} display={"flex"} padding={2}>
      <IconButton onClick={logout}>
        <SvgIcon>
          <LogoutIcon />
        </SvgIcon>
      </IconButton>
    </Box>
  );
};

export default LogoutHeader;
