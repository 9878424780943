import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import { useResponse } from "src/hooks/use-response";

const ErrorPage = (props) => {
  const { isLoading } = props;
  usePageView();

  return isLoading ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <>
      <Seo title="Error: Not Found" />
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
        }}
      >
        <Container maxWidth="md">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 6,
            }}
          >
            <Box
              alt="Not found"
              component="img"
              src="/assets/errors/error-500.png"
              sx={{
                height: "auto",
                maxWidth: "100%",
                width: 150,
              }}
            />
          </Box>
          <Typography align="center" variant={"h4"}>
            An error occurred when starting chat with the assistant.
          </Typography>
          <Typography
            align="center"
            color="text.secondary"
            sx={{ mt: 0.5, fontSize: 12 }}
          >
            Contact the IT department or technical support of the managing
            entity.
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default ErrorPage;
