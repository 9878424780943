import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { SplashScreen } from "src/components/splash-screen";
import { useAuth } from "src/hooks/use-auth";
import useLogout from "src/hooks/use-logout";
import { useRouter } from "src/hooks/use-router";
import { useUser } from "src/hooks/use-user";
import { paths } from "src/paths";

export const WelcomeGuard = (props) => {
  const { children } = props;
  const [userChecked, setUserChecked] = useState(false);
  const router = useRouter();

  const { pathname } = window.location;

  const { isAuthenticated } = useAuth();

  const { performLogout } = useLogout();

  const { user } = useUser();

  const handleAssistantRedirect = () => {
    if (user && user.showWelcomeWizard) {
      if (user?.isAdmin) {
        router.push(paths.welcomeWizard);
        setUserChecked(true);
      } else {
        router.push(paths.userNoAssistatns);
        setUserChecked(true);
      }
    } else if (
      user &&
      !user.showWelcomeWizard &&
      pathname === paths.welcomeWizard
    ) {
      router.push(paths.assistants.list);
      setUserChecked(true);
    } else {
      setUserChecked(true);
    }
  };

  useEffect(() => {
    setUserChecked(false);
    if (isAuthenticated) {
      handleAssistantRedirect();
    } else {
      performLogout();
    }
  }, []);

  if (!userChecked) {
    return <SplashScreen />;
  }

  return <>{children}</>;
};

WelcomeGuard.propTypes = {
  children: PropTypes.any,
};
