import jwtDecode from "jwt-decode";
import React, { createContext, useEffect, useState } from "react";
import { SplashScreen } from "src/components/splash-screen";
import { permissionConfig } from "src/config";
import { useAuth } from "src/hooks/use-auth";
import useService from "src/services/account/useService";
import { useNotifications } from "./notification-context";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({});

  const { showWelcomeWizard } = useService();

  const auth = useAuth();

  const { showDialog } = useNotifications();

  const handleWelcomeWizardError = (err, decodedUser) => {
    const updatedUser = {
      ...decodedUser,
      externalAccount: !decodedUser.sub.startsWith("auth0"),
      isAdmin: decodedUser.permissions.includes(
        permissionConfig.admin_permission_name
      ),
      showWelcomeWizard: false,
    };
    setUser(updatedUser);

    let status = err.status;
    switch (status) {
      case 460:
        showDialog(
          "You can’t perfrom this action because you don’t have an active subscription. Click here to pick your plan.",
          status
        );
        break;
      case 461:
        showDialog(
          "You can’t perfrom this action because there is an issue with your subscription. Click here to fix it.",
          status
        );
        break;
      case 462:
        showDialog(
          "You can’t perfrom this action because your trial has ended. You can upgrade by clicking here.",
          status
        );
        break;
      default:
        break;
    }
  };

  const handleUpdateUserData = () => {
    auth
      .getAccessTokenSilently()
      .then((res) => {
        const decodedUser = jwtDecode(res);
        showWelcomeWizard()
          .then((x) => {
            const updatedUser = {
              ...decodedUser,
              externalAccount: !decodedUser.sub.startsWith("auth0"),
              isAdmin: decodedUser.permissions.includes(
                permissionConfig.admin_permission_name
              ),
              showWelcomeWizard: x.showWelcomeWizard,
            };
            setUser(updatedUser);
          })
          .catch((err) => {
            handleWelcomeWizardError(err, decodedUser);
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch(() => {
        setUser(null);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleUpdateUserData();
  }, [auth]);

  if (isLoading) return <SplashScreen />;

  return (
    <UserContext.Provider value={{ user, isLoading, handleUpdateUserData }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserConsumer = UserContext.Consumer;
